import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Link,
  Flex,
  Image,
  VStack,
  Tooltip,
  Collapse,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Sidebar({ isOpen, lightMode }) {
  const [t] = useTranslation("Sidebar");
  const [sidebarWidth, setSidebarWidth] = useState(0);
  const sidebarRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(null);

  useEffect(() => {
    if (sidebarRef.current) {
      setSidebarWidth(sidebarRef.current.offsetWidth);
    }
  }, [sidebarRef, isOpen]);

  const linkItems = [
    {
      name: t("home"),
      icon: "home.png",
      iconDarkMode: "home_dark.png",
      link: "/",
      alt: `${t("home")}`,
      menu: false,
      children: [],
    },
    {
      name: t("agroups"),
      icon: "home.png",
      iconDarkMode: "home_dark.png",
      link: "/",
      alt: `${t("home")}`,
      menu: true,
      children: [
        { name: "Group2", link: "/group/2" },
        { name: "Group3", link: "/group/3" },
        { name: "Group4", link: "/group/4" },
      ],
    },
    {
      name: t("participate"),
      icon: "email.png",
      iconDarkMode: "email_dark.png",
      link: "/participate",
      alt: `${t("participate")}`,
      menu: false,
      children: [],
    },
    {
      name: t("hosted-decisions"),
      icon: "host.png",
      iconDarkMode: "host_dark.png",
      link: "/hosted-decisions",
      alt: `${t("hosted-decisions")}`,
      menu: false,
      children: [],
    },
    {
      name: t("create-decision"),
      icon: "document.png",
      iconDarkMode: "document_dark.png",
      link: "/create-decisions",
      alt: `${t("create-decision")}`,
      menu: false,
      children: [],
    },
    {
      name: t("edit-group"),
      icon: "document.png",
      iconDarkMode: "document_dark.png",
      link: "/edit-group",
      alt: `${t("edit-group")}`,
      menu: false,
      children: [],
    },
  ];

  return (
    <Flex direction="row">
      <Box
        position="fixed"
        height="100vh"
        paddingTop={8}
        backgroundColor="var(--CLEAR-BACKGROUND)"
        zIndex={1000}
        ref={sidebarRef}
        overflowY="auto"
        pl={4}
      >
        <VStack spacing={4} align="flex-start">
          {linkItems.map((link, i) =>
            isOpen ? (
              link.menu ? (
                <Box key={i} width="100%" pb={-4}>
                  <Link
                    as={RouterLink}
                    to={link.children.length ? null : link.link}
                    className="link"
                    onClick={() =>
                      link.children.length && setOpenMenu(!openMenu)
                    }
                  >
                    <Flex align="center" p={0} mb={-6}>
                      <Box
                        margin={0} 
                        w="25px"
                        h="25px"
                        bg="blue.500"
                        borderRadius="full"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        color="white"
                        fontWeight="bold"
                        fontSize="14px"
                        lineHeight="1"
                        mr={1}
                      >
                        A
                      </Box>
                      <Box className="text-up-select-sidebar">{link.name}</Box>
                      <FontAwesomeIcon
                        icon={openMenu ? faAngleUp : faAngleDown}
                        style={{ marginLeft: "8px", marginTop: "0px" }}
                        cursor="pointer"
                        onClick={()=>setOpenMenu(!openMenu)}
                      />
                    </Flex>
                  </Link>
                  {link.children.length > 0 && openMenu && (
                    <Collapse in={openMenu}>
                      <Flex align="flex-start" direction="column" margin={0} py={4}  gap={2} borderBottom="2px #E8E8E8 solid" mr={12}>
                        {link.children.map((child, childIndex) => (
                          <Flex key={childIndex} mb={1}>
                            <Box
                              w="26px"
                              h="26px"
                              bg="red.500"
                              borderRadius="full"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              color="white"
                              fontWeight="bold"
                              fontSize="14px"
                              lineHeight="1"
                              mr={1}
                            >
                              G
                            </Box>
                            <Link
                              as={RouterLink}
                              to={child.link}
                              className="link"
                              key={childIndex}
                            >
                              <Box className="text-up-sidebar" pt="2px">
                                {child.name}
                              </Box>
                            </Link>

                          </Flex>
                        ))}
                      </Flex>

                    </Collapse>
                  )}
                </Box>
              ) : (
                <Link as={RouterLink} to={link.link} className="link" key={i}>
                  <Flex align="center">
                    <Image
                      src={lightMode ? link.icon : link.iconDarkMode}
                      className="image"
                      alt={link.alt}
                      boxSize="24px"
                      mr={2}
                    />
                    <Box className="text-up-sidebar">{link.name}</Box>
                  </Flex>
                </Link>
              )
            ) : (
              !link.menu && (
                <Tooltip
                  label={link.name}
                  aria-label={`${link.name} tooltip`}
                  key={i}
                  placement="right"
                >
                  <Link
                    as={RouterLink}
                    to={link.link}
                    className="link"
                    _hover={{ textDecoration: "none" }}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <Image
                      src={lightMode ? link.icon : link.iconDarkMode}
                      className="image"
                      alt={link.alt}
                      boxSize="24px"
                    />
                  </Link>
                </Tooltip>
              )
            )
          )}
        </VStack>
      </Box>
      <div style={{ width: sidebarWidth }}></div>
    </Flex>
  );
}
