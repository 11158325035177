import React from "react";
import {
  Modal,
  ModalOverlay,
  Link,
  ModalBody,
  Button,
  ModalContent,
  Text,
  Image,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { TroubleshootTwoTone } from "@mui/icons-material";

export default function CreateDecisionModal({
  editDecisionLink,
  openModal,
  setOpenModal,
}) {
  const [t] = useTranslation("CreateDecisionModal");

  return (
    <Modal
      isOpen={openModal}
      onClose={() => setOpenModal(false)}
    >
      <ModalOverlay />
      <ModalContent className="modal-content-create-decision-modal">
        <ModalBody className="modal-body-text-create-decision-modal">
          <Image
            src="polity.png"
            className="ethelo-images-create-decision-modal"
            alt="Polity"
          />
          <Text
            className="text-decision-ready-to-edit-create-decision-modal"
            role="heading"
            aria-level="1"
          >
            {t("your-decision-is-ready-for-editing")}
          </Text>
          <Text
            className="text-decision-ready-create-decision-modal"
            role="heading"
            aria-level="2"
          >
            {t("your-decision-is-ready")}
          </Text>
          <Link
            className="link-to-edit-decision-create-decision-modal"
            href={editDecisionLink}
            target="_blank"
          >
            <Button className="button-create-decision-modal">
              {t("edit-decision")}
            </Button>
          </Link>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
