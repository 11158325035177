import { useEffect, useState } from "react";
import {
    Flex,
    Input,
    Button,
    InputGroup,
    Stack,
    Text,
    Box,
    FormControl,
    Center,
    Link,
    Checkbox,
    Image,
    Grid,
    InputLeftElement
} from "@chakra-ui/react";
import connectWallet from '../../features/web3/utils/connectMetamask';
import personalSign from '../../features/web3/utils/personalSign';
import authApi from '../../api/auth';
import isMetaMaskInstalled from '../../features/web3/utils/isMetaMaskInstalled';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from "react-router-dom"
import { getErrorMessage } from "utils"
import "./styles.css"
import { maxWidth } from "@mui/system";

const Signup = () => {
    const hasMetamaskExtension = isMetaMaskInstalled()
    const [receiveNotifications, setReceiveNotifications] = useState(false)
    const [agreeWithTerms, setAgreeWithTerms] = useState(false)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const navigate = useNavigate();
    const [t] = useTranslation('Signup');
    const [nameError, setNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [nameErrorText, setNameErrorText] = useState("")
    const [emailErrorText, setEmailErrorText] = useState("")
    const [errorAgreeWithTerms, setErrorAgreeWithTerms] = useState(false)
    const [web3Error, setWeb3Error] = useState(false)
    const [web3TextError, setWeb3TextError] = useState("")
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    const registerInApp = async (name, email) => {
        
        if(!agreeWithTerms){
            setErrorAgreeWithTerms(true)
            return
        } 

        setErrorAgreeWithTerms(false)
        
        const data = { name, email, terms: agreeWithTerms, ethelo_notifications: receiveNotifications }
        authApi
            .signup({ user: data })
            .then(() => {
                navigate('/', { replace: true });
            })
            .catch(e => {
                e?.data?.errors?.forEach(currentError => {
                    if (currentError.source.pointer === "/data/attributes/username") {
                        setNameError(true);
                        setNameErrorText(getErrorMessage(currentError.detail));
                    }
                    else if (currentError.source.pointer === "/data/attributes/email") {
                        setEmailError(true);
                        setEmailErrorText(getErrorMessage(currentError.detail));
                    }
                })
            });
    };

    const connectWithMetamask = async (nonce, publicAddress, data) => {
        const message = `Ethelo Sign Up,${Date.now()},${nonce}`;
        const { signature } = await personalSign({ message, publicAddress });
        if (signature) {
            const user = {
                ...data,
                terms: agreeWithTerms,
                ethelo_notifications: receiveNotifications,
                public_address: publicAddress,
                message,
                signature,
            };
            authApi["join"]({ user })
                .then(() => {
                    navigate('/', { replace: true });
                })
                .catch((e) => {
                    e?.data?.errors?.forEach(currentError => {
   
                    })
                });
        } else {
        }
    };

    const registerInAppWithWeb3 = async (name, email) => {
        if(!agreeWithTerms){
            setErrorAgreeWithTerms(true)
            return
        } 

        setWeb3Error(false)

        setErrorAgreeWithTerms(false)

        const data = { name, email }
        const { publicAddress } = await connectWallet();
        if (publicAddress) {
            authApi.nonce({ user: { public_address: publicAddress } })
                .then(response => {
                    connectWithMetamask(response.nonce, publicAddress, data);
                })
                .catch(e => {
                    e?.data?.errors?.forEach(currentError => {
                            if (currentError.source.pointer === "/data/attributes/username") {
                                setNameError(true);
                                setNameErrorText(getErrorMessage(currentError.detail));
                            }
                            else if (currentError.source.pointer === "/data/attributes/email") {
                                setEmailError(true);
                                setEmailErrorText(getErrorMessage(currentError.detail));
                            } else if(currentError.source.pointer === "/data/attributes/auth"){
                                setWeb3Error(true)
                                setWeb3TextError(getErrorMessage(currentError.detail))
                            }
                    })
                });
        } else {
        }
    };

    useEffect(()=>{
        setEmailError(false)
    },[email])    

    useEffect(()=>{
        setNameError(false)
    },[name])

    useEffect(()=>{
        setErrorAgreeWithTerms(false)
    },[agreeWithTerms])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Flex className="flexColumn-signup" id="main" maxHeight="100vh">
            <Grid className="grid-signup">
                {windowWidth > 768 && <Image src="polity_side.png" className="side-image-signup" minH="100vh" maxH="100vh"/>}
                <Flex className="flexCentered-signup" width="100%" maxHeight="100vh">
                    <Stack className="stackForm-signup" role="form" aria-label="Signup form" width="100%"  minH="100vh" maxH="100vh">
                        <Box className="register-text-without-metamask">{t("register")}</Box>
                        <Box className="welcome-text-without-metamask">{t("welcome-to-polity")}</Box>
                        <FormControl>  
                            <Center>
                                <InputGroup>
                                    <Flex className="flexColumnForErrorMessage-signup" direction="column">
                                        <InputLeftElement className="input-left-element-without-metamask" children={<Image src={'username_icon.png'} alt="Username" />} />
                                        <Input type="text" placeholder={t("username")} aria-labelledby="name" value={name} onChange={e => setName(e.target.value)} className={"input-signup"} />
                                        {nameError && <Text className="errorText-signup" aria-label={nameErrorText}>{nameErrorText}</Text>}
                                    </Flex>
                                </InputGroup>
                            </Center>
                        </FormControl>
                        <FormControl>
                            <Text className="inputText-signup" id="email" role="heading" aria-level="3">{t("email-address")}</Text>
                            <Center>
                                <InputGroup>
                                    <Flex className="flexColumnForErrorMessage-signup">
                                        <InputLeftElement className="input-left-element-without-metamask" children={<Image src={'email_icon.png'} alt="email" />} />
                                        <Input placeholder={t("email-address")} type="email" aria-labelledby="email" value={email} onChange={e => setEmail(e.target.value)} className={"input-signup"} />
                                        {emailError && <Text className="errorText-signup" aria-label={emailErrorText}>{emailErrorText}</Text>}
                                    </Flex>
                                </InputGroup>
                            </Center>
                        </FormControl>
                        <Flex className="flexboxOfCheckbox-signup">
                            <Checkbox className="checkbox-signup" size="lg" id="update me" isChecked={receiveNotifications} onChange={e => setReceiveNotifications(e.target.checked)} aria-checked="false">
                                <Box className="checkbox-text-signup">
                                    {t("update-me")}
                                </Box>
                            </Checkbox>
                        </Flex>
                        <Flex className="flexboxOfCheckbox-signup">
                            <Checkbox className="checkbox-signup" size="lg" id="agree with terms" isChecked={agreeWithTerms} onChange={e => setAgreeWithTerms(e.target.checked)} aria-checked="false">
                                <Box className="checkbox-text-signup">
                                    {t("accept-generals")} <Link className="terms-link">{t("terms-and-conditions")}</Link> {t("of-use")}
                                </Box>
                            </Checkbox>
                        </Flex>
                            {errorAgreeWithTerms && <Text className="errorTermsAndConditions-signup" aria-label={t("accept-terms-and-conditions")}>{t("accept-terms-and-conditions")}</Text>}
                        <Flex className="flexboxWithInformationOfPasswordRecover-signup">
                            <Text className="passwordEmailedText-signup" role="heading" aria-level="4">
                                {t("your-password-will-be-emailed-to-you")}
                            </Text>
                        </Flex>
                        {hasMetamaskExtension && 
                        <Flex direction="column">
                            <Button onClick={() => registerInAppWithWeb3(name, email)} className="button-metamask-signup">
                                <Image className="metamask-logo-signup" src={"metamask_logo.svg"} /> {t("register-with-metamask")}
                            </Button>
                            {web3Error && <Text className="errorText-signup" aria-label={web3TextError}>{web3TextError}</Text>}
                        </Flex>
                        }
                        <Button onClick={() => registerInApp(name, email)} className="button-signup">
                            {t("register")}
                        </Button>
                        <Flex className="bottom-signup">
                            <Box className="login-to-account-signup">{t("already-have-account")}</Box>
                            <Link as={RouterLink} className="login-link-signup" to="/">{t("login-to-your-account")}</Link>
                        </Flex>
                    </Stack>
                </Flex>
            </Grid>
        </Flex>
    );
};

export default Signup;
