import React from "react";
import { Card, Flex, Stack, CardBody, Heading, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import "./styles.css";

export default function ParticipateHeader() {
  const [t] = useTranslation("ParticipateHeader");
  return (
    <Card className="card-participate-decision-header" variant="outline">
      <Flex className="flex-center-participate-decision-header">
        <Stack
          className="stack-participate-decision-header"
          role="main"
          aria-label="Create decision card"
        >
          <CardBody className="card-body-participate-decision-header">
            <Heading
              className="heading-participate-decision-header"
              role="heading"
              aria-level="1"
              size="md"
            >
              {t("decisions-participating")}
            </Heading>
            <Text className="text-participate-decision-header">
              {t("view-all-bgd")}
            </Text>
          </CardBody>
        </Stack>
      </Flex>
    </Card>
  );
}
