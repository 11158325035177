import React, { useState } from "react";
import {
  Card,
  Box,
  Button,
  Text,
  Link,
  Image,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import ModalDecisionCardParticipate from "components/ModalDecisionCardParticipate";
import { timeDifference, formatDate } from "utils"

const DecisionCardParticipate = ({ currentDecision }) => {
  const [t] = useTranslation("DecisionCardParticipate");
  const [isError, setIsError] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ModalDecisionCardParticipate
        isOpen={isOpen}
        onClose={onClose}
        currentDecision={currentDecision}
        isError={isError}
      />
      <Card
        className="card-decision-card-participate"
      >
        <Box className="box-image-decision-card-participate">
          <Image
            className="image-decision-card-participate"
            src={
              isError
                ? "standar_image.png"
                : currentDecision["image-url"] || "standar_image.png"
            }
            alt={"Decision"}
            onError={() => setIsError(true)}
          />
        </Box>
        <Text className="title-decision-card-participate">
          {currentDecision["title"]}
        </Text>
        <Link
          className="main-link-decision-card-participate"
          href={
            currentDecision["current-mode"] === "Unpublished"
              ? currentDecision?.links?.preview
              : currentDecision?.links?.visit
          }
          target="_blank"
        >
          {currentDecision["current-mode"] === "Unpublished"
            ? currentDecision?.links?.preview
            : currentDecision?.links?.visit}
        </Link>
        {currentDecision.invited && (
          <Link href={currentDecision?.links?.visit}>
            <Button className="special-link-button-decision-card-participate">
              <FontAwesomeIcon
                icon="fa-regular fa-envelope"
                className="icon-button-decision-card-participate"
              />
              {t("accept-invitation")}
            </Button>
          </Link>
        )}

        {!currentDecision.invited &&
          currentDecision["current-mode"] === "Open" && (
            <Link href={currentDecision?.links?.visit}>
              <Button className="special-link-button-decision-card-participate">
                <FontAwesomeIcon
                  icon="fa-regular fa-moon"
                  className="icon-button-decision-card-participate"
                />
                {currentDecision.completed ? currentDecision.completed : 0}%{" "}
                {t("completed")}
              </Button>
            </Link>
          )}

        {!currentDecision.invited &&
          currentDecision["current-mode"].includes("Finished") && (
            <Link href={currentDecision?.links?.results}>
              <Button className="special-link-button-decision-card-participate">
                <FontAwesomeIcon
                  icon="fa-regular fa-check"
                  className="icon-button-decision-card-participate"
                />
                {t("view-results")}
              </Button>
            </Link>
          )}

        <Flex direction="column">
          <Flex className="date-flex-decision-card-participate">
            <Text className="date-tag-decision-card-participate">
              {t("opens-on")}:
            </Text>
            <Text ml={0} className="date-text-decision-card-participate">
              {currentDecision["open-at"] ? (
                <>
                  {formatDate(currentDecision["open-at"])} (
                  {timeDifference(currentDecision["open-at"])})
                </>
              ) : (
                <>{t("tbd")}</>
              )}
            </Text>
          </Flex>
          <Flex className="date-flex-decision-card-participate">
            <Text className="date-tag-decision-card-participate">
              {t("closes")}:
            </Text>
            <Text ml={0} className="date-text-decision-card-participate">
              {currentDecision["close-at"] ? (
                <>
                  {formatDate(currentDecision["close-at"])} (
                  {timeDifference(currentDecision["close-at"])})
                </>
              ) : (
                <>{t("tbd")}</>
              )}
            </Text>
          </Flex>
        </Flex>
        <Button
          className="button-details-decision-card-participate"
          rightIcon={<FontAwesomeIcon icon="fa-solid fa-chevron-down" />}
          justifyContent="space-between"
          onClick={() => onOpen()}
        >
          {t("show-details")}
        </Button>
      </Card>
    </>
  );
};

export default DecisionCardParticipate;
