import React from "react";
import { Card, Flex, Box, Text, Grid, CardBody } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { useCurrentUserContext } from "features/user/contexts/user-provider";
import { useCurrentPaymentsContext } from "features/payments/contexts/payments-provider";

export default function WalletHeader() {
  const [t] = useTranslation("WalletHeader");
  const { user } = useCurrentUserContext();
  const { name } = user;
  const { membershipDate, subscription, sharesAmount, totalInvestment } =
    useCurrentPaymentsContext();

  const formatDateToMMYYYY = (isoString) => {
    if (!isoString) return t("none");
    const date = new Date(isoString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${year}`;
  };

  return (
    <Card className="card-wallet-header" variant="outline">
      <Flex p={4} className="flex-card-wallet-header">
        <Box>
          <Text className="wallet-text-wallet-header">
            {t("total-investment")}
          </Text>
          <Text className="wallet-amount-wallet-header">
            ${totalInvestment.toLocaleString()}
          </Text>
        </Box>
        <Flex gap={8} className="secondary-flex-wallet-header">
          <Box>
            <Text className="name-header-wallet-header">{t("name")}</Text>
            <Text className="user-name-wallet-header">{name}</Text>
          </Box>
          <Box>
            <Text className="name-header-wallet-header">
              {t("member-since")}
            </Text>
            <Text className="user-name-wallet-header">
              {formatDateToMMYYYY(membershipDate)}
            </Text>
          </Box>
        </Flex>
      </Flex>
      {totalInvestment > 0 && (
        <Grid
          templateColumns={{ base: "1fr", md: "1fr 1fr" }}
          gap={6}
          px={8}
          py={4}
        >
          <Card bg="rgba(5, 71, 243, 0.06)" boxShadow="none" borderRadius="10px">
            <CardBody>
              <Flex alignItems="center" p={0}>
                <Box className="circle-wallet-header"
                >
                  {subscription ? 1 : 0}
                </Box>
                <Box>
                  <Text
                    my={0}
                    className="secondary-card-main-text-wallet-header"
                  >
                    {t("membership-token")}
                  </Text>
                  <Text
                    my={1}
                    className="secondary-card-secondary-text-wallet-header"
                  >
                    ${subscription ? 100 : 0}
                  </Text>
                </Box>
              </Flex>
            </CardBody>
          </Card>

          <Card bg="rgba(5, 71, 243, 0.06)" boxShadow="none"  borderRadius="10px">
            <CardBody>
              <Flex alignItems="center" p={0}>
                <Box className="circle-wallet-header">{sharesAmount}</Box>
                <Box>
                  <Text
                    mb={0}
                    className="secondary-card-main-text-wallet-header"
                  >
                    {t("investment-share")}
                  </Text>
                  <Text
                    my={1}
                    className="secondary-card-secondary-text-wallet-header"
                  >
                    $
                    {subscription
                      ? (totalInvestment - 100).toLocaleString()
                      : totalInvestment.toLocaleString()}
                  </Text>
                </Box>
              </Flex>
            </CardBody>
          </Card>
        </Grid>
      )}
    </Card>
  );
}
