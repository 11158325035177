import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  IconButton,
  Flex,
  Input,
  Tooltip,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Text,
  Link,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import {
  CopyIcon
} from "@chakra-ui/icons";
import LeftArrow from "images/leftArrow";
import RightArrow from "images/rightArrow";
import { useCurrentDecisionsContext } from "features/decisions/contexts/decisions-provider";
import "./styles.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ModalDecisionCardHost from "components/ModalDecisionCardHost";
import ModalInviteParticipants from "components/ModalInviteParticipants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const dataOfTable = [
  {
    id: 0,
    name: "John Doe",
    email: "john@example.com",
    web3Address: "0x1234abcd5678efgh9101ijklmn2134opqr5678st",
    status: "Active",
    roles: "Admin",
    infl: 1,
    inviteSent: "2024-09-25",
    lastSignIn: "2024-09-30",
  },
  {
    id: 1,
    name: "Jane Smith",
    email: "jane@example.com",
    web3Address: "0x5678efgh9101ijkl2134mnopqr5678abcd1234st",
    status: "Pending",
    roles: "User",
    infl: 1,
    inviteSent: "2024-08-15",
    lastSignIn: "2024-08-20",
  },
  {
    id: 2,
    name: "Michael Brown",
    email: "michael.brown@example.com",
    web3Address: "0x2234abcd1234efgh5678ijklmn9101opqr5678st",
    status: "Active",
    roles: "User",
    infl: 1,
    inviteSent: "2024-07-10",
    lastSignIn: "2024-07-15",
  },
  {
    id: 3,
    name: "Emily Davis",
    email: "emily.davis@example.com",
    web3Address: "0x9876efgh5678abcd9101ijklmn2134opqr5678st",
    status: "Inactive",
    roles: "Admin",
    infl: 1,
    inviteSent: "2024-06-01",
    lastSignIn: "2024-06-15",
  },
  {
    id: 4,
    name: "Chris Johnson",
    email: "chris.johnson@example.com",
    web3Address: "0x3456ijkl5678abcd1234mnopqr5678efgh9101st",
    status: "Active",
    roles: "User",
    infl: 1,
    inviteSent: "2024-05-20",
    lastSignIn: "2024-05-30",
  },
  {
    id: 5,
    name: "Amanda Clark",
    email: "amanda.clark@example.com",
    web3Address: "0x4567mnop9101abcd5678ijklmn2134opqr5678st",
    status: "Pending",
    roles: "Manager",
    infl: 1,
    inviteSent: "2024-04-10",
    lastSignIn: "2024-04-15",
  },
  {
    id: 6,
    name: "David Taylor",
    email: "david.taylor@example.com",
    web3Address: "0x5678efgh9101ijkl2134opqr5678abcd1234mnop",
    status: "Active",
    roles: "User",
    infl: 1,
    inviteSent: "2024-03-25",
    lastSignIn: "2024-03-30",
  },
  {
    id: 7,
    name: "Sophia White",
    email: "sophia.white@example.com",
    web3Address: "0x6789ijkl1234mnop5678abcd9101opqr5678efgh",
    status: "Inactive",
    roles: "Admin",
    infl: 1,
    inviteSent: "2024-02-20",
    lastSignIn: "2024-02-28",
  },
  {
    id: 8,
    name: "Daniel Lee",
    email: "daniel.lee@example.com",
    web3Address: "0x7890abcd5678ijkl9101mnopqr1234opqr5678st",
    status: "Active",
    roles: "Manager",
    infl: 1,
    inviteSent: "2024-01-15",
    lastSignIn: "2024-01-20",
  },
  {
    id: 9,
    name: "Olivia Walker",
    email: "olivia.walker@example.com",
    web3Address: "0x8901ijkl1234mnop5678abcd9101opqr5678efgh",
    status: "Pending",
    roles: "User",
    infl: 1,
    inviteSent: "2024-12-01",
    lastSignIn: "2024-12-05",
  },
];

const listOfSorts = [
  { label: "Name", value: "name" },
  { label: "Email", value: "email" },
  { label: "Status", value: "status" },
];

const TableOfCurrentDecision = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: invitateOpen,
    onOpen: invitateOnOpen,
    onClose: invitateOnClose,
  } = useDisclosure();
  const [currentDecision, setCurrentDecision] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const listOfPagesSizes = [6, 8, 10, 15, 20, 50];
  const [pageSize, setPageSize] = useState(listOfPagesSizes[0]);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [dataFilteredAndSorted, setDataFilteredAndSorted] = useState(dataOfTable)
  const [dataToDisplay, setDataToDisplay] = useState(dataOfTable)
  const { tableDecisionsHostingData, dataOfDecisionsAlreadyCalled } =
    useCurrentDecisionsContext();
  const { id } = useParams();
  const navigate = useNavigate();

  const getLowerNumber = (numberA, numberB) => {
    if (numberA < numberB) {
      return numberA;
    } else {
      return numberB;
    }
  };

  const previousPage = () => {
    if (pageIndex <= 0) return;
    setPageIndex(pageIndex - 1);
  };

  const nextPage = () => {
    if (dataOfTable.length <= (pageIndex + 1) * pageSize) return;
    setPageIndex(pageIndex + 1);
  };

  const getPaginationButtons = (pagesNumber) => {
    const paginationButtons = () => {
      const buttonList = [];
      for (let i = 0; i < pagesNumber; i++) {
        buttonList.push(
          <Button
            className="button-change-page-decision-copy-list"
            color={pageIndex === i ? "var(--MAIN-TEXT)" : "var(--MAIN-GREY)"}
            bgColor={
              pageIndex === i ? "var(--MAIN-GREY)" : "var(--CLEAR-BACKGROUND)"
            }
            onClick={() => setPageIndex(i)}
            key={i}
          >
            {i + 1}
          </Button>
        );
      }
      return buttonList;
    };

    return <>{paginationButtons()}</>;
  };

  const truncateString = (str) => {
    return str.length > 9 ? `${str.substring(0, 9)}...` : str;
  };

  useEffect(() => {
    const getDataOfCurrentDecision = async () => {
      try {
        if (id) {
          const dataOfTable = tableDecisionsHostingData.dataOfTable;
          const matchingDecision = dataOfTable.find(
            (item) => item["decision-id"] == id
          );

          if (matchingDecision) {
            setCurrentDecision(matchingDecision);
          } else {
            navigate("/hosted-decisions");
          }
        }
      } catch (error) {
        navigate("/hosted-decisions");
      }
    };

    if (dataOfDecisionsAlreadyCalled) getDataOfCurrentDecision();
  }, [id, dataOfDecisionsAlreadyCalled]);

  useEffect(()=> {
    const filteredData = dataOfTable.filter((row) => {
      const matchesSearch =
        row.name.toLowerCase().includes(search.toLowerCase()) ||
        row.email.toLowerCase().includes(search.toLowerCase());
      return matchesSearch;
    })

    const sortedData = filteredData.sort((a, b) => {
      if (sortBy === "name") {
        return a.name.localeCompare(b.name);
      } else if (sortBy === "email") {
        return a.email.localeCompare(b.email);
      } else if (sortBy === "status") {
        return a.status.localeCompare(b.status);
      }
      return 0;
    });

    setDataFilteredAndSorted(sortedData)
  }, [search, sortBy])

  useEffect(()=>{
    const positionOfElementToShow = pageIndex * pageSize
    const positionOfLastElementToShow = getLowerNumber(dataFilteredAndSorted.length, ((pageIndex + 1) * pageSize))

    const newDataToDisplay = dataFilteredAndSorted.slice(positionOfElementToShow, positionOfLastElementToShow)

    setDataToDisplay(newDataToDisplay)
  },[dataFilteredAndSorted, pageIndex])

  return (
    <>
      <ModalDecisionCardHost
        isOpen={isOpen}
        onClose={onClose}
        currentDecision={currentDecision}
      />
      <ModalInviteParticipants
        isOpen={invitateOpen}
        onClose={invitateOnClose}
        currentDecision={currentDecision}
      />
      {currentDecision ? (
        <Box className="main-box-table-of-current-decision">
          <Box className="secondary-box-table-of-current-decision">
            <Flex
              justifyContent="space-between"
              py={4}
              borderBottom="2px solid var(--INPUT-BACKGROUND)"
            >
              <Flex direction="column">
                <Stack role="main" aria-label="Table header">
                  <Text className="decision-name-table-of-current-decision">
                    {currentDecision?.title}
                  </Text>
                  <Link className="decision-link-table-of-current-decision">
                    {currentDecision?.links?.visit}
                  </Link>
                </Stack>
              </Flex>
              <Button
                className="button-table-of-current-decision"
                onClick={() => onOpen()}
              >
                Details
              </Button>
            </Flex>
            <Flex gap={4} mt={4} mb={6}>
              <Box flexGrow={1}>
                {
                  <InputGroup>
                    <Input
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search"
                      className="input-table-of-current-decision"
                    />
                  </InputGroup>
                }
              </Box>
              <Flex gap={4}>
                <Box>
                  <Select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    h={12}
                  >
                    {listOfSorts.map((currentSort, i) => (
                      <option key={i} value={currentSort.value}>
                        {currentSort.label}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Button
                  className="button-table-of-current-decision"
                  onClick={() => invitateOnOpen()}
                >
                  Invite participants
                </Button>
              </Flex>
            </Flex>

            <Table
              variant="simple"
              size="md"
              borderWidth="1px"
              borderColor="gray.200"
            >
              <Thead className="thead-table-of-current-decision">
                <Tr>
                  <Th className="th-table-of-current-decision">Name</Th>
                  <Th className="th-table-of-current-decision">Email</Th>
                  <Th className="th-table-of-current-decision">Web3 Address</Th>
                  <Th className="th-table-of-current-decision">Status</Th>
                  <Th className="th-table-of-current-decision">Roles</Th>
                  <Th className="th-table-of-current-decision">Infl</Th>
                  <Th className="th-table-of-current-decision">Invite Sent</Th>
                  <Th className="th-table-of-current-decision">Last Sign In</Th>
                  <Th className="th-table-of-current-decision">Completion</Th>
                  <Th className="th-table-of-current-decision"></Th>
                </Tr>
              </Thead>
              <Tbody>
                {dataToDisplay.map((row) => (
                  <Tr key={row.id}>
                    <Td className="td-table-of-current-decision">{row.name}</Td>
                    <Td className="td-table-of-current-decision">
                      <Tooltip label={row.email} placement="top">
                        <Flex align="center" justify="space-between">
                          {truncateString(row.email)}
                          <IconButton
                            aria-label="Copy email"
                            icon={<CopyIcon />}
                            size="sm"
                            onClick={() =>
                              navigator.clipboard.writeText(row.email)
                            }
                          />
                        </Flex>
                      </Tooltip>
                    </Td>
                    <Td className="td-table-of-current-decision">
                      <Tooltip label={row.web3Address} placement="top">
                        <Flex align="center" justify="space-between">
                          {truncateString(row.web3Address)}
                          <IconButton
                            aria-label="Copy web3 address"
                            icon={<CopyIcon />}
                            size="sm"
                            onClick={() =>
                              navigator.clipboard.writeText(row.web3Address)
                            }
                          />
                        </Flex>
                      </Tooltip>
                    </Td>
                    <Td className="td-table-of-current-decision">{row.status}</Td>
                    <Td className="td-table-of-current-decision">{row.roles}</Td>
                    <Td className="td-table-of-current-decision">{row.infl}</Td>
                    <Td className="td-table-of-current-decision td-date-table-of-current-decision">{row.inviteSent}</Td>
                    <Td className="td-table-of-current-decision td-date-table-of-current-decision">{row.lastSignIn}</Td>
                    <Td className="td-table-of-current-decision">N/A</Td>
                    <Td>
                      <Button size="sm" className="action-button-table-of-current-decision">Password Reset</Button>
                      {/*<Button
                        size="sm"
                        colorScheme="yellow"
                        leftIcon={<RepeatIcon />}
                        ml={2}
                      >
                        Resend Invitation
                      </Button>*/}
                      <Button
                        size="sm"
                        ml={2}
                        className="action-button-table-of-current-decision"
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-pencil-alt"
                        />
                      </Button>
                      <Button
                        size="sm"
                        ml={2}
                        className="action-button-table-of-current-decision"
                      >
                        <FontAwesomeIcon icon="fa-solid fa-trash" 
                        />
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          {windowWidth > 768 ? (
            <Flex
              className="outer-flex-participate-page"
              role="region"
              aria-label="pagination handler with arrows"
            >
              <Flex className="column-flex-participate-page">
                <Text className="showing-text-participate-page">
                  {"showing"}{" "}
                  {dataOfTable.length > 0 ? pageIndex * pageSize + 1 : 0} {"to"}{" "}
                  {getLowerNumber(
                    dataOfTable.length,
                    (pageIndex + 1) * pageSize
                  )}{" "}
                  {"of"} {dataOfTable.length} {"participations"}
                </Text>
              </Flex>
              <Flex className="flex-pagination-participate-page">
                <Button
                  className="button-arrow-page-participate-page"
                  cursor={pageIndex > 0 ? "pointer" : "not-allowed"}
                  onClick={() => previousPage()}
                >
                  <LeftArrow active={pageIndex > 0} />
                </Button>
                <Flex className="flex-pagination-buttons-participate-page">
                  {getPaginationButtons(
                    Math.ceil(dataOfTable.length / pageSize)
                  )}
                </Flex>
                <Flex
                  onClick={() => nextPage()}
                  className={"flex-change-page-participate-page"}
                  opacity={
                    pageIndex < Math.ceil(dataOfTable.length / pageSize) - 1
                      ? 1
                      : 0.84
                  }
                  _hover={{
                    cursor:
                      pageIndex < Math.ceil(dataOfTable.length / pageSize) - 1
                        ? "pointer"
                        : "not-allowed",
                  }}
                >
                  <Button
                    onClick={() => nextPage()}
                    className="button-arrow-page-participate-page"
                    cursor={
                      pageIndex < dataOfTable.length - 1
                        ? "pointer"
                        : "not-allowed"
                    }
                  >
                    <RightArrow active={pageIndex < dataOfTable.length - 1} />
                  </Button>
                </Flex>
              </Flex>
              <Box></Box>
            </Flex>
          ) : (
            <Flex className="responsive-bottom-flex-participate-page">
              <Flex
                onClick={() => previousPage()}
                className={`flex-change-page-participate-page`}
                opacity={pageIndex > 0 ? 1 : 0.84}
                _hover={{ cursor: pageIndex > 0 ? "pointer" : "not-allowed" }}
              >
                <Button
                  className="button-arrow-page-participate-page"
                  cursor={pageIndex > 0 ? "pointer" : "not-allowed"}
                >
                  <LeftArrow active={pageIndex > 0} />
                </Button>
              </Flex>
              <Flex className="flex-pagination-buttons-participate-page">
                {getPaginationButtons(Math.ceil(dataOfTable.length / pageSize))}
              </Flex>
              <Flex
                onClick={() => nextPage()}
                className={`flex-change-page-participate-page ${
                  pageIndex < Math.ceil(dataOfTable.length / pageSize) - 1
                    ? "flex-change-page-opacity-total-participate-page"
                    : "flex-change-page-opacity-partial-participate-page"
                }`}
                opacity={
                  pageIndex < Math.ceil(dataOfTable.length / pageSize) - 1
                    ? 1
                    : 0.84
                }
                _hover={{
                  cursor:
                    pageIndex < Math.ceil(dataOfTable.length / pageSize) - 1
                      ? "pointer"
                      : "not-allowed",
                }}
              >
                <Button
                  className="button-arrow-page-participate-page"
                  cursor={
                    pageIndex < dataOfTable.length - 1
                      ? "pointer"
                      : "not-allowed"
                  }
                >
                  <RightArrow active={pageIndex < dataOfTable.length - 1} />
                </Button>
              </Flex>
            </Flex>
          )}
        </Box>
      ) : null}
    </>
  );
};

export default TableOfCurrentDecision;
