import React from "react";
import { Box, Image, Text, Button, Flex, useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
import { useTranslation } from "react-i18next";
import "./styles.css";
import ModalCreateDecision from "../ModalCreateDecision";

export default function CopyDecisionCard({
  currentDecision,
  setCreateDecisionData,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { title, image, info, links } = currentDecision;
  const navigate = useNavigate();
  const [t] = useTranslation("TemplateCard");

  const goToCreateFormRoute = (data) => {
    setCreateDecisionData(data, false);
    navigate("/create-decision-form", { replace: true });
  };

  const handleVisitClick = () => {
    const url = links.visit ? links.visit : links.self ? links.self : "";
    if (url) {
      window.open(url, "_blank");
    }
  };

  return (
    <>
      <ModalCreateDecision
        isOpen={isOpen}
        onClose={onClose}
        decisionData={currentDecision}
      />
      <Box
        className="main-box-copy-decision-card"
        role="region"
        aria-label="Template card"
      >
        <Text
          className="text-title-copy-decision-card"
          role="hecopy-decision-cardading"
          aria-level="1"
        >
          {title}
        </Text>
        <Image
          src={image || "standar_image.png"}
          alt="Copy decision card"
          className="image-copy-decision-card"
        />
        <Text
          className="html-text-copy-decision-card"
          role="heading"
          aria-level="2"
        >
          {HTMLReactParser(info)}
        </Text>
        <Flex className="flex-copy-decision-card" gap={8}>
          <Button
            onClick={() => onOpen()  /*goToCreateFormRoute(currentDecision)*/}
            className="first-button-copy-decision-card"
            flex="1"
          >
            {t("create")}
          </Button>
          <Button
            flex="1"
            onClick={() => handleVisitClick()}
            className="second-button-copy-decision-card"
          >
            {t("demo")}
          </Button>
        </Flex>
      </Box>
    </>
  );
}
